@import './colors';
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');

@keyframes spin {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-weight: 400;
  color: $textDark;
  background: $background;
  font-family: 'Rubik', sans-serif;
}

/*   ------   SHARED   ------   */

.pageWrapper {
  display: flex;
  min-height: calc(100vh - 60px);
  margin-top: 60px;

  .sidenavArea {
    width: 230px;
    flex-shrink: 0;

    @media screen and (max-width: 1595px) {
      width: 40px;
    }
    @media screen and (max-width: 850px) {
      width: 0;
    }
  }

  .pageArea {
    width: 100%;
    padding: 1em;

    @media screen and (max-width: 500px) {
      padding: .5em;
    }
  }
}

.hideOnMobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.hideOnDesktop {
  @media screen and (min-width: 769px) {
    display: none;
  }
}

.card-body {
  @media screen and (max-width: 500px) {
    padding: 12px;
  }
}

/*   ------   NAVBAR   ------   */

nav {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 60px;
  background: $white;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5%;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);

  .logoA {
    // margin-right: 4em;
    margin-right: auto;

    .logo {
      width: 56px;
    }
  }

  .navSearchForm {
    margin-right: auto;

    .navInputField {
      background: rgba($buttonPurple, .1);
      border: transparent;
      font-size: .9rem;
      padding: .5em 1em;
      border-radius: 18px;
    }

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  .mainA {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    padding: .7em;
    margin-right: 1.8em;
    color: $textDark;

    &:hover {
      color: $textDarkHover;
      transition: .2s;
    }

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  .secondaryA {
    font-weight: 500;
    font-size: .95rem;
    text-decoration: none;
    padding: .7em;
    margin: auto;
    margin-right: 0;
    margin-left: .5em;
    color: $buttonPurple;

    &:hover {
      color: $buttonPurpleHover;
      transition: .2s;
      cursor: pointer;
    }

    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  .hamburgerApk {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    .burgerLinesApk {
      display: block;
      position: relative;
      width: 35px;
      height: 3px;
      background: $textDark;
      transition: .3s;

      &::before {
        content: '';
        width: 35px;
        height: 3px;
        background: $textDark;
        position: absolute;
        top: -11px;
        left: 0px;
        transition: .3s;
      }

      &::after {
        content: '';
        width: 35px;
        height: 3px;
        background: $textDark;
        position: absolute;
        bottom: -11px;
        left: 0px;
        transition: .3s;
      }
    }

    .hamOpen {
      background: transparent;
      transition: .3s;

      &::before {
        top: 0px;
        transform: rotate(45deg);
        transition: .3s;
      }

      &::after {
        bottom: 0px;
        transform: rotate(-45deg);
        transition: .3s;
      }
    }

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 851px) {
      display: none;
    }
  }

  .mobileNavList {
    position:fixed;
    top: 60px;
    left: 0;
    width: 100%;
    background: $white;
    opacity: 0;
    pointer-events: none;
    transition: .3s;

    .accountDiv {
      width: 221px;
      margin: 1.4em auto;
    }

    .secondaryAPhone {
      font-weight: 500;
      font-size: .95rem;
      text-decoration: none;
      padding: .7em;
      color: $buttonPurple;
    }

    .mobileSideLink {
      display: block;
      width: 100%;
      padding: .7em;
      text-align: center;
    }

    @media screen and (min-width: 851px) {
      display: none;
    }
  }

  .mobileNavLOpen {
    pointer-events: all;
    opacity: 1;
    transition: .3s;
  }
}

/*   ------   SIDENAV   ------   */

.sidenavWrapper {
  z-index: 9998;
  width: 230px;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  background: $sidenavBlue;
  padding: .5em 0;

  .sidenavLink {
    display: flex;
    align-items: center;
    padding: .5em;
    margin: .2em 0;

    .slIco {
      width: 24px;
      margin-right: .65em;
      fill: rgba($textLight, .7);
    }

    .slSpan {
      color: rgba($textLight, .8);
      font-size: .95rem;
    }

    &:hover .slIco {
      fill: rgba($textLight, 1);
      transition: .2s;
    }

    &:hover .slSpan {
      color: rgba($textLight, 1);
      transition: .2s;
    }
  }

  @media screen and (max-width: 1595px) {
    clip-path: polygon(0 0, 17.39% 0, 17.39% 100%, 0% 100%);
    transition: .3s;

    &:hover {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      transition: .3s;
    }
  }
  @media screen and (max-width: 850px) {
    display: none;
  }
}

/*   ------   SIGN IN   ------   */

.signInWrapper {
  margin-top: 60px;

  .fs95 {
    font-size: .95rem;
    font-weight: 400;
  }
}

/*   ------   MY DOCUMENTS   ------   */

.groupSwitch {
  border: transparent;
  background: transparent;
  color: $buttonPurple;
  padding: .5em;
  margin-bottom: 1em;
}

/*   ------   UPLOAD DOCUMENT   ------   */

.feedbackForm,
.uplForm {
  display: flex;
  flex-wrap: wrap;

  .formField {
    width: 32%;
    margin-right: 2%;
    margin-bottom: 1em;

    &:nth-child(3n) {
      margin-right: 0;
    }

    @media screen and (max-width: 800px) {
      width: 48.5%;
      margin-right: 3%;

      &:nth-child(3n) {
        margin-right: 3%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-right: 0;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }

  .btnFix {
    width: 100%;
  }

  .fs952 {
    font-size: .95rem;
    font-weight: 400;
  }

  .filePreview {
    width: 100%;
    border: 1px solid rgba($sidenavBlue, .2);
    border-radius: 5px;
    padding: .4em 1em;
    margin-top: .8em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .delFile {
      border: 1px solid $delRed;
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background: transparent;
      color: $delRed;

      &:hover {
        background: $delRedHover;
        transition: .2s;
        cursor: pointer;
        color: white;
      }
    }
  }
}

.feedbackForm {
  .form-group {
    width: 100% !important;
  }
}

/*   ------   FILE DROPZONE   ------   */

.dropArea {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: 2px dashed rgba($textDark, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;

  .dtPassive {
    font-weight: 500;
    color: rgba($textDark, .7);
    text-align: center;
  }

  .dtActive {
    font-weight: 500;
    color: $buttonPurple;
    text-align: center;
  }
}

/*   ------   HEATMAP   ------   */

.heatmapWrapper {
  display: grid;
  grid-template-columns: 120px repeat(3, calc(33.3% - 40px));
  grid-template-rows: 90px repeat(10, 50px);
  grid-auto-flow: column;

  .numberSpan {
    justify-self: center;
    margin: auto;
    font-weight: 400;
    color: rgb(84, 89, 94);
    font-size: .85rem;
    text-align: center;
    padding: .6em;

    @media screen and (max-width: 500px) {
      font-size: .75rem;
      padding: .3em;
    }
  }

  #numSpan1 {
    grid-area: 2 / 1 / 3 / 2;
  }
  #numSpan2 {
    grid-area: 3 / 1 / 4 / 2;
  }
  #numSpan3 {
    grid-area: 4 / 1 / 5 / 2;
  }
  #numSpan4 {
    grid-area: 5 / 1 / 6 / 2;
  }
  #numSpan5 {
    grid-area: 6 / 1 / 7 / 2;
  }
  #numSpan6 {
    grid-area: 7 / 1 / 8 / 2;
  }
  #numSpan7 {
    grid-area: 8 / 1 / 9 / 2;
  }
  #numSpan8 {
    grid-area: 9 / 1 / 10 / 2;
  }
  #numSpan9 {
    grid-area: 10 / 1 / 11 / 2;
  }
  #numSpan10 {
    grid-area: 11 / 1 / 12 / 2;
  }
  #numSpanB1 {
    grid-area: 1 / 2 / 2 / 3;
  }
  #numSpanB2 {
    grid-area: 1 / 3 / 2 / 4;
  }
  #numSpanB3 {
    grid-area: 1 / 4 / 2 / 5;
  }

  .heatmapCell {
    width: calc(100% - 1px);
    height: calc(100% - 1px);
    background: rgb(90, 116, 172);
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .03s;

    &:hover {
      box-shadow: inset 0px 0px 0px 2px #ffc400;
      transition: .03s;
      cursor: pointer;
    }

    &.inputElement {
      input {
        border: none !important;
        background: transparent !important;
        outline: none !important;
        width: 100%;
        text-align: center;
        font-size: 14px;
      }
    }

    &.marked {
      background: #ffc400 !important;
      user-select: none;
    }
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 96px repeat(3, calc(33.3% - 32px));
  }
}

/*   ------   DOCUMENT LIST   ------   */

.dlPaginator {
  .btn {
    margin: 0 5px;

    &.disabled,
    &:disabled {
      pointer-events: none;
    }
  }
}

.dlContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .docContainer {
    width: 18.5%;
    margin-right: 1.875%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba($textDark, .1);
    box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 1em .3em;
    margin-bottom: 1em;

    .docIcon {
      width: 30px;
      fill: rgba($textDark, .7);
      margin-bottom: 1.2em;
    }

    .docTitle {
      text-align: center;
      font-size: .9rem;
      margin-bottom: .8em;
    }

    .docPercentage {
      font-weight: 700;
      color:rgba($textDark, .7);
      font-size: 1.1rem;
      margin-top: auto;
    }

    &:nth-child(5n) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.45);
      transition: .2s;
      cursor: pointer;
    }

    &:hover .docPercentage {
      color: $textDark;
      transition: .2s;
    }

    &:hover .docIcon {
      fill: $textDark;
      transition: .2s;
    }

    @media screen and (max-width: 1650px) {
      width: 23.875%;
      margin-right: 1.5%;

      &:nth-child(5n) {
        margin-right: 1.5%;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 1200px) {
      width: 31%;
      margin-right: 3.5%;

      &:nth-child(5n) {
        margin-right: 3.5%;
      }

      &:nth-child(4n) {
        margin-right: 3.5%;
      }

      &:nth-child(3n) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 500px) {
      width: 48.25%;
      margin-right: 3.5%;

      &:nth-child(3n) {
        margin-right: 3.5%;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}

/*   ------   ANALYSIS SCREEN   ------   */

.analysisEmpty {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1.1rem;
  color: rgba($textDark, .7);
}

.analysisLoader {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dataLoaderIcon {
  font-size: 3rem;
  color: $buttonPurple;
  animation: spin 2s linear infinite;
}

.dataLoaderText {
  font-size: 1.1rem;
  color: $buttonPurple;
  font-weight: 700;
}

/*   ------   BROWSE SCREEN   ------   */

.map-container {
  min-height: calc(100vh - 300px);
  height: 600px;
  width: 100%;
}

.mapPopupTitle {
  font-size: .9rem;
  font-weight: 700;
}

/*   ------   BROWSE SCREEN   ------   */

.graphWrapper {
  width: 100%;
  height: 530px;
  margin-bottom: 20px;

  .graphBar {
    height: 35px;
    margin-bottom: 18px;
    width: 100%;
    background-color: burlywood;
    padding: .35em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      box-shadow: inset 0px 0px 0px 2px #ffc400;
      transition: .03s;
      cursor: pointer;
    }
  }
}

.flexed {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  .btn-filters {

  }
}